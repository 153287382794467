const goToPipInfo = function(options) {
  try {
    const { platform } = options
    let urlHost = ''
    if(platform.toUpperCase() === 'WECHATMINIPROGRAM') {
      // 门店模式
      urlHost = '/subpages/in-store/pages/pip/pip'
    } else {
      // 门店助手
      urlHost = '/pages/_pip/_pip'
    }
    const url = `${urlHost}?itemNo=${options.itemNo}&itemType=${options.itemType}&prePageType=order_detail&orderNo=${options.orderNo}`
    if (window.wx) {
      window.wx.miniProgram.navigateTo({ url });
    } else {
      console.error("scan-go go to payment without window.wx:", options)
    }
  } catch (error) {
    console.error("scan-go go to payment error:", error, options)
  }
}
export default goToPipInfo
