import { clickCollectService } from '@/service'

const getDefaultState = () => {
  return {
    status: '',
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    updateStatus(state, payload) {
      state.status = payload
    },
  },
  actions: {
    /**
     * @description 记录关闭提示层状态
     * @param ikeaOrderNo 订单编号
     */
    async doClosePrompt({ state, commit }, payload) {
      const { ikeaOrderNo, orderNo } = payload
      if (ikeaOrderNo && !state.status) {
        commit('updateStatus', 'PENDING')
        await clickCollectService.closePrompt({ ikeaOrderNo, orderNo })
          .then(res => {
            if (res instanceof Error) {
              commit('updateStatus', 'REJECTED')
            } else {
              commit('updateStatus', 'FULFILLED')
            }
          }).catch((err) => {
            console.error(err)
            commit('updateStatus', 'REJECTED')
          })
        commit('updateStatus', '')
      }
    }
  }
}
