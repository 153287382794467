
// localStorage的keys维护
export const LOCAL_STORAGE_KEYS = {
  ORDERS_LIST_OFFLINE_TIP_SHOW: 'ORDERS_LIST_OFFLINE_TIP_SHOW', // 已废弃
  ORDERS_LIST_ORDER_CHANNEL_TYPE: 'ORDERS_LIST_ORDER_CHANNEL_TYPE', // online/offline
  ORDERS_STORES_LIST: 'stores', // 店铺列表
  ORDERS_LIST_INIT_TAB: 'initTab', // tab
  ORDERS_LIST_NOTICE_TIP_TIME: 'ORDERS_LIST_NOTICE_TIP_TIME', // 订单列表通知提示时间戳
  ORDERS_DETAIL_NOTICE_TIP_TIME: 'ORDERS_DETAIL_NOTICE_TIP_TIME', // 订单列表通知提示时间戳
}

// 上游来源
export enum Origin_Enums {
  SCAN_GO = 'SHOP_COMPANION_MINI_P', // scan&go——订单列表/详情页
  INSPIRATION = 'my-inspiration-review', // 我的灵感页——待晒单
  MY_PAGE = 'me', // 我的——待晒单
  WE_CHAT_WORK = 'WE_CHAT_WORK' // 企业微信
}

export const DEFAULT_PAGINATION = {
  pageIndex: 1,
  pageSize: 10,
  isFinished: false,
  isLoading: false,
  isRefreshing: false,
}

export const MEDIA_WIDTH = 740

// AB实验id们
export const AB_EXPERIMENT_IDS = {
  GIT_PRODUCT_TIP: 'git_order_detail_communication',
  EXTENDED_CLICK_COLLECT: 'extended_click_collect',
  CANCEL_ORDER_MOVE_BACK: 'cancel_order_move_back',
  PARCEL_PRE_DELIVERY_TIME: 'parcel_pre_delivery_time'
}

// cms配置的id们
export const CMS_IDS = {
  ASSEMBLY_SERVICE_CH: '1aa90991a8db488eb6add45a9644268b', // assemblyService中文配置
  ASSEMBLY_SERVICE_EN: '717fe1d30ce7495f8f20a2eceef6436e', // assemblyService英文配置
  ASSERT_CONFIG: '2b14c6af5f484e029cefac14f42376d3', // order配置
  ORDER_SPARE_CMSID_CN: '03d77d50d931450e8123e2232adf1355', // 中文备用id（prod与qa同一id）
  ORDER_SPARE_CMSID_EN: '7ad8f111775d4e4aa8db09fbc2ff7cd1', // 英文备用id（prod与qa同一id）
}

// platform_vars for tracking
// @Monica Ma
export const Platform_Vars = {
  M2: 'M2',
  PcWeb: 'PcWeb',
  MobileWeb: 'MobileWeb',
  iOS: 'iOS',
  ANDROID: 'Android',
  Harmony: 'Harmony',
  WechatMiniprogram: 'WechatMiniprogram',
  StoreCompanion: 'StoreCompanion',
  unknown: 'unknown',
}
