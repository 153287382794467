<template>
  <div class="prod-images-wrapper">
    <lazy-component :class="`${classNames('prod-images', `prod-images-${image_size}`)}`" style="display: flex" v-if="image_size !== 'small'">
      <!-- <template v-for="item, index in usefulList.slice(0, limit)" v-bind:key="index">
        <img class="image-view" v-lazy="item.itemPicture" />
      </template> -->
      <template v-for="item, index in usefulList.slice(0, limit)" v-bind:key="index">
        <div class="image-preview" v-lazy:background-image="item.itemPicture" v-if="item.itemPicture">
        </div>
        <div v-else>
          <div class="image-preview">
            <Image :errorImageSrc="errorImageSrc"/>
          </div>
        </div>
      </template>
    </lazy-component>
    <div v-else :class="`${classNames('prod-images', `prod-images-small`)}`" style="display: flex">
      <template v-for="item, index in usefulList.slice(0, limit)" v-bind:key="index">
        <div class="image-preview">
          <Image :src="item.itemPicture" :errorImageSrc="errorImageSrc"/>
        </div>
      </template>
    </div>

    <div
      v-if="usefulList.length > limit && ellipsis"
      class="more"
    >
      <span>...</span>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import classNames from 'classnames'
import Image from '../Image'
import { OrderType_Enums } from '@/enums/order'

export default defineComponent({
  props: {
    list: Array,
    size: {
      type: String,
      default: 'normal'
    },
    limit: {
      type: Number,
      default: 3,
    },
    ellipsis: {
      type: Boolean,
      default: true,
    },
    itemData: {
      type: Object
    }
  },
  components: {
    Image,
  },
  setup(props) {
    const image_size = ref(props.itemData?.__asserts__?.isSimpleServiceOrder ? 'small' : 'normal')
    const usefulList = computed(() => {
      return props.list.filter(item => !!item)
    })
    const errorImageSrc = (props.itemData?.orderType === OrderType_Enums.FOOD) ? 'https://res.app.ikea.cn/content/u/20240130/c68dc29eeff44fba8642a4ddd8c43100.png' : ''
    return {
      image_size,
      usefulList,
      errorImageSrc,
      classNames,
    }
  },
})
</script>

<style lang="less" scoped>
@import '../../../ikea.order.less';

@media only screen and (max-width: @base-width-desktop) {
  .prod-images-wrapper {
    display: flex;
    align-items: center;

    .prod-images {
      .image-preview {
        .s750(width, 128px);
        .s750(height, 128px);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: 5px;
      }

      .image-view {
        .s750(width, 128px);
        .s750(height, 128px);
        margin-right: 5px;
      }

      .more {
        display:flex;
        align-items:center;
        justify-content: center;
        min-width: 24px;
        .s750(min-width, 48px);
        height: 100%;
        font-size: 1rem;
        span {
          transform: translateY(-4px);
        }
      }

      &-small {
        .image-preview {
          .s750(width, 64px);
          .s750(height, 64px);
          margin-right: 10px;
          margin-top: 5px;
          margin-bottom: 5px;
        }

        .image-view {
          .s750(width, 64px);
          .s750(height, 64px);
          margin-right: 10px;
          margin-top: 5px;
          margin-bottom: 5px;
        }

      }
    }
  }
}

@media only screen and (min-width: @base-width-desktop) {
  .prod-images-wrapper {
    display: flex;
    align-items: center;
    .image-preview {
      width: 80px;
      height: 80px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      margin-right: .5rem;
    }

    .image-view {
      width: 80px;
      height: 80px;
      margin-right: .5rem;
    }

    .more {
      display:flex;
      align-items:center;
      justify-content: center;
      height: 80px;
      width: 80px;
      font-size: 1rem;
    }
    .prod-images {
      &-small {
        .image-preview {
          width: 64px;
          height: 64px;
          margin-right: 10px;
          margin-top: 5px;
          margin-bottom: 5px;
        }

        .image-view {
          width: 64px;
          height: 64px;
          margin-right: 10px;
          margin-top: 5px;
          margin-bottom: 5px;
        }

      }
    }
  }
}

</style>
