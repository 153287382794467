import bridge from '@/bridge'

// https://airtable.com/app0gfnyycCngKl1O/tblVe6ZzqgDm4TBCx/viwo6YxHHNuGouDuz?blocks=bipecAKoRbNQbBd8t
// https://airtable.com/app7WYf27886fv0al/tbl2UPR3Zc953EgL4/viwUomTFCNRTLkdzN?blocks=hide  企业订单
export default {
  eventName: {
    clickData: 'clickData',
    pageView: 'pageView',
    expose: 'impressionData',
  },
  pageType_var: {
    orderList: 'order_list',
    orderDetail: 'order_detail',
    orderReview: 'review_order_list',
  },
  pageName_var: {
    orderList: '订单列表页',
    orderDetail: '订单详情页'
  },
  eventType_var: {
    click: 'click',
    impression: 'impression',
    pageView: 'page_view',
    orderDetail: 'order_detail',
    refundSuccessful: 'refund_successful',
    repurchase: 'repurchase',
    selfService: 'self_service',
    applyForInvoice: 'apply_for_invoice',
    checkInvoice: 'check_invoice',
    cancelOrder: 'cancel_order',
    proceedPayment: 'proceed_payment',
    addToCart: 'add_to_cart',
    checkLogisticsInfo: 'check_logistics_info',
    eReceipt: 'e_receipt',
    postSalesServiceProgress: 'post_sales_service_progress',
    checkRefund: 'check_refund',
    goToReview: 'go_to_review',
    myReview: 'my_review',
    checkService: 'check_service',
    cancellationConfirm: 'cancellation_confirm',
    cancellationSuccess: 'cancellation_success',
    cancellationFail: 'cancellation_fail',
    cancellationCancel: 'cancellation_cancel',
    cancellationCancelIcon: 'cancellation_cancel_icon',
    cancelOrderMoveBack: 'cancel_order_move_back',
    pushNotification: 'push_notification',
    goodsImage: 'goods_image',
    contactCustomerSupport: 'contact_customer_support',
    close: 'close'
  },
  objectType_var: {
    order: 'order',
    refundSuccessful: 'refund_successful',
    repurchase: 'repurchase',
    selfService: 'self_service',
    applyForInvoice: 'apply_for_invoice',
    checkInvoice: 'check_invoice',
    cancelOrder: 'cancel_order',
    proceedPayment: 'proceed_payment',
    addToCart: 'add_to_cart',
    checkLogisticsInfo: 'check_logistics_info',
    eReceipt: 'e_receipt',
    postSalesServiceProgress: 'post_sales_service_progress',
    checkRefund: 'check_refund',
    postSalesAssemblyReservation: 'post_sales_assembly_reservation',
    copyOrderId: 'copy_order_id',
    myAssemblyReservation: 'my_assembly_reservation',
    assemblyServiceInfo: 'assembly_service_info',
    pulseCx: 'pulse_cx',
    memberPoints: 'member_points',
    goToReview: 'go_to_review',
    myReview: 'my_review',
    checkService: 'check_service',
    onlineKefu: 'online_kefu',
    orderSearch: 'order_search',
    orderTab: 'order_tab',
    orderFilter: 'order_filter',
    confirmAddToBag: 'confirm_add_to_bag',
    cancelAddToBag: 'cancel_add_to_bag',
    more: 'more',
    orderFilterEntrance: 'order_filter_entrance',
    checkIkeaServiceDetail: 'go_to_ikea_service_detail',
    checkServiceDetail: 'check_service_detail',
    checkChargeDetail: 'check_charge_detail',
    submitAssemblyService: 'submit_assembly_service',
    reschedule: {
      click: 'reschedule',
      confirm: 'rescheduleConfirm',
      cancel: 'rescheduleCancel',
      return: 'rescheduleReturn'
    },
    goToPipInfo: 'go_to_pip_info',
    unfold: 'unfold',
    fold: 'fold',
    totalDiscountIcon: 'total_discountIcon',
    checkAssServiceDetail: 'check_service_detail',
    checkPickupPositon: 'check_pickup_positon',
    checkShopDetial: 'check_shop_detial',
    checkPickupDetial: 'check_pickup_detial',
    checkShopMap: 'check_shop_map',
    goToTrackAndTraceDetail: 'go_to_track_and_trace_detail',
    goToBookAssemblyService: 'go_to_book_assembly_service',
    transportChange: {
      TRUCK: 'transport_truck',
      PARCEL: 'transport_parcel',
      default: 'transport_default',
    },
    transportMap: 'transport_map',
    contactCourier: 'contact_courier',
    itemsList: 'items_list',
    PenndingForReviewlistGoReview: 'PenndingForReviewlist_goReview',
    contactCSC: 'contactCSC',
    chatService: 'chat_service',
    contactCSCIcon: 'contact_CSC_icon',
    storePage: 'store_page',
    extended_cc_details: 'extended_cc_details',
    serviceFeedback: 'service_feedback',
    serviceProductList: 'service_product_list',
    serviceProduct: 'service_product'
  },
  objectName_var: {
    tabs: {
      ALL: 'total_order_tab',
      WAITING_FOR_PAYMENT: 'unpaid_tab',
      PAID: 'unshipped_order_tab',
      DELIVERED: 'unreceived_order_tab',
      COMPLETED: 'pennding_for_review_tab'
    },
    orderChannel: {
      COMPOSITE: 'all_order',
      ONLINE: 'online_order',
      OFFLINE: 'offline_order',
    },
  },
  module_var: {
    cancellationPopup: 'cancellation_popup',
    pushNotification: 'push_notification',
    packageDelayReminder: 'package_delay_reminder',
    orderDetailProductRecommendation: 'order_detail_product_recommendation',
    userPost: 'user_post',
    service: 'service',
    serviceProductPopup: 'service_product_popup'
  },
  attributeName: {
    orderStatus_var: 'orderStatus_var',
    cancellationReasonCode_var: 'cancellationReasonCode_var',
    cancellationFailCode_var: 'cancellationFailCode_var',
    ifAddToCart_var: 'ifAddToCart_var',
    orderServiceStatus_var: 'orderServiceStatus_var',
    assemblyPrice: 'assembly_price',
    assemblyProductList: 'assembly_product_list',
    quantity_var: 'quantity_var'
  },
  attributeValue: {
    waitingForPayment: 'WAITING_FOR_PAYMENT',
    paid: 'PAID',
    canceled: 'CANCELED',
    delivered: 'DELIVERED',
    completed: 'COMPLETED',
    collected: 'COLLECTED',
  },
  variableProperties: {
    pageIdVar: 'pageId_var',
    objectIdVar: 'objectId_var',
    platformVar: 'platform_var'
  }
}
