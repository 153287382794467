import orderStatus from './orderStatus'
export default {
  back: 'Return',
  cancel: 'Cancel',
  iknow: 'Got It',
  close: 'Close',
  finished: 'Complete',
  date: {
    mmdd: 'MM/DD',
    yyyymmdd: 'YYYY/MM/DD',
    sameDay: 'Today',
    nextDay: 'Tomorrow',
    afterTomorrow: 'Day after tomorrow'
  },
  mobileNoticeTip: 'Enable notifications to stay informed about shipping, after-sales and other updates',
  mobileNoticeTipSwitch: 'Enable',
  orderStatus,
}
