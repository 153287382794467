<template>
  <router-view/>
</template>

<script>
import { defineComponent, onBeforeMount, onMounted } from 'vue'
// import { useRoute } from 'vue-router'
import bridge from '@/bridge'
import { Origin_Enums } from '@/enums/index'
import { setEvar } from '@/utils/gio'
import { formatQuery } from '@/utils'
import * as Sentry from '@sentry/vue'
import qs from 'qs'
import { useStore } from 'vuex'
import Cookies from 'js-cookie';
// Vue.use(VueI18n)

export default defineComponent({
  setup() {
    const store = useStore()
    const query = formatQuery()
    onMounted(() => {
      if (query.vconsole === 'false') {
        setTimeout(() => {
          const vconsole = document.querySelector('#__vconsole')
          // console.log(vconsole)
          vconsole.style.display = 'none'
        }, 600)
      }

      store.dispatch('globalState/getCMSAssertConfig')
      store.commit('globalState/updateProcessEnv', {
        DMP_GAODE_MAP_URL: import.meta.env.VITE_APP_DMP_GAODE_MAP_URL,
        DMP_GAODE_MAP_PREVIEW_URL: import.meta.env.VITE_APP_DMP_GAODE_MAP_PREVIEW_URL
      })
      store.commit('globalState/updateOrigin', query.origin)
      store.commit('globalState/updateExtras', query.extras)
      store.commit('globalState/updateIsFromScanGo', query.origin === Origin_Enums.SCAN_GO)
      store.dispatch('storesState/fetchStoreList')

      setEvar({
        utmSource_evar: query.utm_source,
        utmCampaign_evar: query.utm_campaign,
        utmMedia_evar: query.utm_medium,
        utmContent_evar: query.utm_content,
        utmTerm_evar: query.utm_term,        
      })
    })
    onBeforeMount(async() => {
      const externalHeader = document.querySelector('.i-layout__header')
      const headerMsgEl = document.querySelector('.nav-header-message')
      const qiankun = document.getElementById('__qiankun_microapp_wrapper_for_order_list__') || document.getElementById('__qiankun_microapp_wrapper_for_order_detail__')
      store.commit('globalState/updateExternalHeader', externalHeader)
      store.commit('globalState/updateWin', {
        innerHeight: window.innerHeight
      })
      try {
        const lang = await bridge.currentLanguage()
        store.commit('globalState/updateLang', lang)
      } catch (e) {
        Sentry.captureException(e)
      }
      try {
        /**
         * isMobileWeb => using userAgent instead
         */
        const isMobileWeb = Cookies.get('platform') === 'MobileWeb'
        if (isMobileWeb && qiankun) {
          const headerHeight = (headerMsgEl?.offsetHeight || 0) + (externalHeader?.offsetHeight || 0)
          qiankun.style.cssText = `height: ${window.innerHeight - headerHeight}px`
        }
        store.commit('globalState/updateQianKun', qiankun)
        const { value: isNoticeOpen } = await bridge.getNotificationPermission()
        /**
         * using cookie to check if opened in app platform
         */
        const isApp = Cookies.get('platform') === 'IOS' || Cookies.get('platform') === 'ANDROID'
        if (isApp && isNoticeOpen) {
          store.commit('globalState/updateIsNoticeOpen', isNoticeOpen === 'true')
        }
      } catch (e) {
        Sentry.captureException(e)
      }
    })
  },
})
</script>

<style lang="less">
@media only screen and (max-width: @base-width-desktop) {
  html, body {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    background: #FFFFFF;
  }

  #app {
    font-family: 'Noto IKEA Latin', 'Noto Ikea SC', 'Noto Sans SC', 'Noto Sans', Roboto, 'Open Sans', system-ui, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    height: 100%;
    width: 100%;
  }
}

@media only screen and (min-width: @base-width-desktop) {
  html, body {
    padding: 0;
    margin: 0;
    height: auto;
    overflow: auto;
    #app {
      font-family: 'Noto IKEA Latin', 'Noto Ikea SC', 'Noto Sans SC', 'Noto Sans', Roboto, 'Open Sans', system-ui, sans-serif !important;
      height: auto;
      overflow: auto;
    }
  }
}

.van-cell {
  &.van-cell-strong {
    font-weight: 600;
    .van-icon-arrow {
      font-weight: 600;
      color: #111;
    }
  }
}

</style>
