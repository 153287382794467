export default {
  title: '取消订单',
  confirm_title: '确定取消订单吗？',
  tips: '请选择取消订单原因，订单取消后无法恢复',
  gitTips: '提醒：订单中有部分商品需要调货，将于到仓后包裹统一发货，请耐心等待。 若继续取消，请选择取消订单的原因，取消成功后无法恢复',
  tips_for_paid_order_618: '取消订单后，本订单获取的所有优惠券将会被自动收回。若所发优惠券已使用，提交申请后，请等待宜家客服联系您，我们会在退款时，等价扣减已使用优惠券金额。',
  tips_for_paid_order: '取消订单审核通过后，订单金额将于24小时内退回到您的支付账号。如果您预定了组装服务，也将一并取消退款处理。',
  assembly_service: {
    tips: '请通过以下方式联系客服取消，如果已联系请耐心等待处理结果。',
    call_service: '拨打客服电话 {phone_number}',
    contact_online_service: '联系在线客服',
  },
  reasons: {
    'OC-0401': '价格太贵',
    'OC-0402': '运费太贵',
    'OC-0403': '地址选错',
    'OC-0404': '款式选错',
    'OC-0405': '收货时间选错了',
    'OC-0406': '不想要了',
    'OC-0407': '其它',
    '0501': '风格或尺寸不合适',
    '0502': '购买商品超出需要',
    '0503': '在其他地方找到更好的产品/价格',
    '1502': '未按约定时间送达',
    '8501': '买错了商品',
    '8502': '商品介绍有误导/错误信息'
  },
  actions: {
    confirm: '确定取消',
    cancel: '暂不取消',
    next_step: '下一步',
    apply: '提交申请',
  },
  results: {
    sys_error: '系统异常',
    do_cancel_success: '取消成功',
    do_apply_success: '申请成功',
    cancel_exception_a: '退单异常，请稍后重试',
    cancel_exception_b: '退单异常，请等待宜家客服联系',
    cancel_exception_c: '退单异常，请联系宜家客服',
    cancel_exception_d: '您已经提交过退单请求，请耐心等待',
    cancel_exception_e: '请联系宜家客服进行退单',
    cancel_exception_f: '此订单已返利，取消订单需联系人工客服',
  },
  paid: {
    submitted: '成功提交订单取消申请',
    refund: '钱款将在24小时内按原支付方式返还',
    processing: {
      prefix: '你已提取消订单申请，正在处理中，请耐心等待。如有疑问，请'
    },
    refunded: '退款成功，查看钱款去向 >',
    partRefunded: '部分退款成功，查看钱款去向 >'
  },
  moveBack: {
    text: '取消后，将订单中所有商品加入购物袋'
  },
  processing: {
    title: '取消申请处理中',
    beforeDispatchStatusTip: '如取消成功会在2小时退款，如取消失败客服会在24小时与您联系',
    dispatchedStatusTip: '我们会在收到申请后24小时内通知您结果，请注意短信的接收以及保持手机畅通。',
    statusAction: '查看详情',
    tips1: '我们会在收到申请后尽快通知您结果，请注意短信的接收以及保持手机畅通。以下是一些常见问题：',
    tips2: '如有更多相关疑问，可通过以下方式联系我们',
    rpaSwitchTipTitle: '请联系客服处理取消订单',
    rpaSwitchTipDesc: '很抱歉由于系统正在维护中，无法自动取消订单，为了能够让我们尽快帮助你处理取消订单，请直接通过以下方式联系我们：',
  },
  csc: {
    contact_online_service: {
      title: '联系在线客服',
      tips: '周一至周日 9:00 - 22:00',
    },
    call_service: {
      title: '拨打客服电话',
      tips: '周一至周日 9:00 - 18:00',      
    }
  },
  ib: {
    error: {
      text: '此订单已返利，取消订单需联系人工客服'
    }
  }
}
