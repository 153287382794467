<template>
  <div :class="`${classNames('prod-card-wrapper', `prod-card-wrapper-${size}`)}`">
    <van-card
      @click.prevent.stop="handleGoToPip(item)"
      style="width: 100%;"
      class="prod-card prod-card-rich"
    >
      <template #thumb>
        <div class="thumb-container">
          <Image class="thumb" :src="itemPicture" :errorImageSrc="errorImageSrc"/>
          <template v-if="item.isFree">
            <i class="tag">{{$t('message.order_detail.product.tags.isFree')}}</i>
          </template>
          <template v-if="item.hasDiscount">
            <i class="tag">{{$t('message.order_detail.product.tags.hasDiscount')}}</i>
          </template>
          <template v-if="item.asisProduct">
            <i class="tag asis-tag">{{$t('message.order_detail.product.tags.asisProduct')}}</i>
          </template>
          <template>
            <slot name="cancelPcitureTag"/>
          </template>
        </div>
      </template>
      <template #title>
        <h4>{{item.itemName}}</h4>
        <slot name="orderNo"></slot>
      </template>
      <template #desc v-if="compact([item.productTypeName, item.validDesignText, item.referenceMeasurement]).length || item.vtpDesignID">
        <p v-if="compact([item.productTypeName, item.validDesignText, item.referenceMeasurement]).length">
          {{compact([item.productTypeName, item.validDesignText, item.referenceMeasurement]).join(', ')}}
        </p>
        <p v-if="item.vtpDesignID">{{$t('message.order_detail.product.design_code')}}{{item.vtpDesignID}}</p>
      </template>
      <template #price v-if="hasPrice">
        <div :class="['price-tag-wrapper', ifBtnShow(item) ? 'price-tag-wrapper__btn' : '']">
          <div class="price-tag-l">
            <slot name="priceTagLeft"></slot>
            <strong v-if="!withOutAmount && !isNaN(price)">
              <ik-price :price="parseFloat(price)" />
            </strong>
          </div>
          <div class="price-tag-r">
            <div class="btn" v-if="ifBtnShow(item)">
              <IButton
                small
                variant="secondary"
                :loading="ifBtnLoading(item)"
                @click.prevent.stop="() => addSingleToCart(item)"
              >
                {{ $t('message.order_list.order.actions.add_to_cart') }}
              </IButton>
            </div>
            <div class="amountWithOutPrice">
              <span v-if="item.quantity">x{{item.quantity}}</span>
            </div>
          </div>
        </div>
      </template>
    </van-card>
    <IModal 
      type="Sheets"
      size="small"
      v-model:show="modalShow"
      className="prod-card-modal"
      :title="lang.message.order_detail.asisProduct.title"
      @onClose.once="handleModalClose"
    >
      <template #default>
        <div class="modal-content">
          <div class="title">
            {{  $t('message.order_detail.asisProduct.desc1') }}
          </div>
          <div class="desc">
            {{  $t('message.order_detail.asisProduct.desc2') }}
          </div>
        </div>
      </template>

      <template #footer>
        <IButton variant="primary" @click="handleModalClose">{{$t('message.system.iknow')}}</IButton>
      </template>
    </IModal>
  </div>
</template>

<script>
import bridge from '@/bridge'
import classNames from 'classnames'
import { computed, defineComponent, ref, inject, onMounted } from 'vue'
import Content from '../../../ikea-routers/content'
import { lang } from '@/locales'
import { getProductTypeShort, formatQuery } from '@/utils'
import { IButton, toast, IModal } from '@ikeacn/ui'
import { compact } from 'lodash'
import { useStore } from 'vuex'
import Image from '../Image'
import IkPrice from '../Price/index.vue'
import { Origin_Enums } from '@/enums'
import { OrderType_Enums } from '@/enums/order'

const query = formatQuery()

export default defineComponent({
  components: {
    IButton,
    IkPrice,
    // IkSpaceBetween: SpaceBetween,
    Image,
    IModal
  },
  props: {
    item: Object,
    withOutAmount: Boolean,
    hasPrice: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'normal'
    },
    from: { // 调用组件的来源，ORDER_DETAIL：订单详情；
      type: String,
      default: ''
    },
    order: {
      type: Object,
    }
  },
  setup(props) {
    const itemPicture = ref(props.item.itemPicture)
    const detailTracker = inject('detailTracker')
    const platform = ref('unknown')

    const store = useStore()
    const { globalState } = store.state

    const modalShow = ref(false)

    const handleGoToPip = async(item) => {
      if (item.itemType === 'SERVICE') {
        return
      }
      // `/pages/pip/pip?itemNo=${itemNo}&itemType=${itemType}&prePageType=order_detail&orderNo=${orderNo}`
      if (globalState.isFromScanGo) {
        const extras = globalState.extras || {}
        if (extras.isAutoJump || extras.isUnbindOrder) {
          // https://jira.digital.ingka.com/browse/CNODI-2154 AC07
          detailTracker({
            objectType: global.trackerConfig.objectType_var.addToCart,
            extraParams: {
              module_var: 'service',
              object_var: 'service_products',
              objectName_var: '服务商品',
              pageId_var: props.order?.orderNO,
            }
          })
        } else {
          detailTracker({
            objectType: global.trackerConfig.objectType_var.addToCart,
            extraParams: {
              id_var: 'goodsImage',
              module_var: 'product_detail',
              object_var: 'product_detail',
              objectName_var: '商品详情',
              objectId_var: item.itemNO,
              storeCode_var: props.order?.storeInfo?.lscCode,
              pageId_var: item.itemNO,
            }
          })
        }
        // https://jira.digital.ingka.com/browse/CNODI-3132
        if(
          ( platform.value === 'WechatMiniprogram' && (query['internal_scene'] === 'store_mode') ) // 门店模式
          || (platform.value === 'StoreCompanion') // 门店助手
        ) {
          bridge.goToPipInfo({
            itemNo: item.itemNO,
            itemType: item.itemType,
            prePageType: 'order_detail',
            orderNo: props.order.orderNO,
            platform: platform.value
          })
        } else {
          return
        }
      } else {
        detailTracker({
          objectType: global.trackerConfig.objectType_var.goToPipInfo,
          extraParams: {
            objectId_var: item.itemNO
          }
        })

        // feature/3188
        if (item.asisProduct) {
          modalShow.value = true
          return
        }

        if (item.itemType) {
          if (item.itemType === 'VTP') {
            bridge.navigateTo(`ikea://content/virtual_product_detail?id=${item.itemNO}`)
          } else {
            bridge.navigateTo(Content.Pip({
              id: item.itemNO,
              type: item.itemType,
            }))
          }
        }
      }
    }

    const ifBtnShow = (item) => (props.from === 'ORDER_DETAIL') && (['SPR', 'ART'].indexOf(item.itemType) > -1) && !globalState.isFromScanGo && !globalState.extras.isIBAdmin && (globalState.origin !== Origin_Enums.WE_CHAT_WORK)

    const errorImageSrc = (props.order?.orderType === OrderType_Enums.FOOD) ? 'https://res.app.ikea.cn/content/u/20240130/c68dc29eeff44fba8642a4ddd8c43100.png' : ''
    
    const price = computed(() => {
      const priceKey = props.from === 'ASSEMBLY_SERVICE' ? 'assemblyUnitPrice' : 'listPrice'
      return parseFloat(props.item[priceKey])
    })

    const ifBtnLoading = (item) => !!item.isLoading

    const addSingleToCart = (item) => {
      const productFullId = getProductTypeShort(item.itemType) + item.itemNO
      if (productFullId) {
        detailTracker({
          objectType: global.trackerConfig.objectType_var.addToCart,
          extraParams: {
            objectId_var: productFullId
          }
        })
        item.isLoading = true
        bridge.request.addToCart({
          products: [{
            productFullId,
            quantity: 1,
          }]
        }).then((res) => {
          const {
            data: {
              products = []
            } = {}
          } = res
          const [product = {}] = products

          const {
            subProducts = []
          } = product || {}
          const [subProduct = {}] = subProducts

          toast({
            text: subProduct.message || product.message || lang.message.recommendation.addProductsToShoppingCart.status.success,
            subtle: true,
            horizontalOffset: 0,
            verticalOffset: 44,
          })

          item.isLoading = false
          bridge.postMessage({ name: 'refreshShoppingCart' })
        }).catch(() => {
          toast({
            text: lang.message.recommendation.addProductsToShoppingCart.status.failed,
            subtle: true,
            horizontalOffset: 0,
            verticalOffset: 44,
          })
          item.isLoading = false
          bridge.postMessage({ name: 'refreshShoppingCart' })
        })
      }
    }

    const handleModalClose = () => { modalShow.value = false }

    onMounted(async() => {
      const { value: _platform } = await bridge.currentPlatform()
      platform.value = _platform.platform
    })

    return {
      itemPicture,
      price,
      classNames,
      handleGoToPip,
      compact,
      addSingleToCart,
      ifBtnShow,
      ifBtnLoading,
      handleModalClose,
      globalState,
      lang,
      errorImageSrc,
      modalShow
    }
  },
})
</script>

<style lang="less" scoped>
.prod-card-wrapper {
  --van-card-background-color: transparent;
  --van-card-padding: 0;

  .amountWithPrice {
    font-size: .75rem;
    // position: absolute;
    right:0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: #959595;
    }
  }

  // .amountWithOutPrice {

  // }

  .van-card {
    margin: 8px 0;
  }

  &-normal {
    --van-card-thumb-size: 72px;
    --van-padding-xs: 10px;
    .thumb {
      width: 72px;
      height: 72px;
      // margin-right: 1rem;

      &-container {
        position: relative;
        height: 100%;
        .tag {
          position: absolute;
          width: 100%;
          text-align: center;
          display: block;
          bottom: 0;
          color: #fff;
          background-color: #CC0008;
          height: 1.25rem;
          line-height: 1.25rem;
          font-size: .75rem;
          font-style: normal;
        }
        .cancel-tag {
          position: absolute;
          width: 100%;
          text-align: center;
          display: block;
          bottom: 0;
          color: #fff;
          background-color: #888888;
          height: 1.25rem;
          line-height: 1.25rem;
          font-size: .75rem;
          font-style: normal;
        }
        .asis-tag {
          background-color: #00853E;
        }
      }
    }
  }

  &-small {
    --van-card-thumb-size: 48px;
    h4, p {
      line-height: 16px;
    }
    .thumb {
      width: 48px;
      height: 48px;
      margin-right: .5rem;

      &-container {
        position: relative;
        .tag {
          position: absolute;
          width: 100%;
          text-align: center;
          display: block;
          bottom: 0;
          color: #fff;
          background-color: #CC0008;
          height: 1.25rem;
          line-height: 1.25rem;
          font-size: .75rem;
          font-style: normal;
        }
      }

    }
  }

  .prod-card {
    cursor: pointer;
    &-simple {
      margin: .75rem 0;
    }
    :deep(.thumb-container) {
      height: 100%;
    }
  }

  h4 {
    color: #111;
    font-size: .75rem;
    font-weight: 700;
    line-height: 16px;
  }

  p {
    font-weight: 400;
    line-height: 16px;
    color: #666666;
  }

  .price-tag-wrapper {
    right: 0;
    bottom: 0;
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    &__btn {
      bottom: -5px;
    }
    .price-tag-l {
      display: flex;
      flex: 0 0 auto;
    }
    .price-tag-r {
      flex: 1 1 auto;
      text-align: right;
      align-items: center;
      display: inline-flex;
      justify-content: space-between;
      padding-left: 10px;
      flex-direction: row-reverse;
      .amountWithOutPrice {
        display: inline-block;
        height: 100%;
      }
      .btn {
        display: inline-block;
      }
    }
  }
  
}

</style>

<style lang="less">
.prod-card {
  .van-card__thumb {
    margin-right: 20px !important;
  }
}

.prod-card-modal {
  .i-modal-header {
    border-bottom: 1px solid #DFDFDF !important;
  }
  .modal-content {
    .title {
      color: #111;
      font-size: 18px;
      font-weight: 700;
      line-height: 26px;
      margin-bottom: 10px;
    }
    .desc {
      color: #484848;
      font-size: 14px;
      line-height: 22px;
    }
  }
}

.cancel-tag {
  position: absolute;
  width: 100%;
  text-align: center;
  display: block;
  bottom: 0;
  color: #fff;
  background-color: #888888;
  height: 1.25rem;
  line-height: 1.25rem;
  font-size: .75rem;
  font-style: normal;
}

  .price-tag-wrapper {
    .price-tag-r {
      .btn {
        .i-btn--small {
          .i-btn__inner {
            height: 32px !important;
            min-height: 32px !important;
            padding: 0 1rem !important;
            color: #484848;
          }
          .i-btn__loader {
            background-color: #0058A3;
            width: 0.3rem;
            height: 0.3rem;
          }
        }
      }
    }
  }
</style>
