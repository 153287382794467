import './polyfills'
import '@ikeacn/marketing-ui/lib/index.css';
import '@ikeacn/ui/lib/index.css';
import '@ikeacn/business/lib/index.css';
import 'vant/lib/index.css';
import "moment/dist/locale/zh-cn";
// import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/vue";
import ikea from 'ikea-jssdk';
import Cookies from 'js-cookie';
import { compact } from 'lodash';
import qs from 'qs';
import {
  ActionSheet, Button, Card, Cell,
  CellGroup, Checkbox,
  CheckboxGroup, Col, CountDown, Empty, Icon, Lazyload, List, Loading, NavBar, NoticeBar,
  Notify, Overlay,
  Popover, Popup, PullRefresh, Radio, RadioGroup, Row, Sidebar,
  SidebarItem, Skeleton, Step,
  Steps, Toast, Search,
} from 'vant';
import { createApp } from "vue";
import { createI18n } from 'vue-i18n';
import App from './App.vue';
import './bifrost/base.less';
import bridge from './bridge';
import messages from './locales';
import router from './router';
import { store } from './store';
import {
  IkButton, IkCard, IkIcon, IkItemsTube, IkLayout, IkNotice, IkOrderNo,
  IkOrderStatus, IkPrice, IkProdCard,
  IkProdImages, IkServiceCard, IkSpaceBetween,
  IkSpaceBetweenCell, IkAlertMessage, IkTag, IKCell
} from './stories/components';
import { IAxiosInstance, initAxiosInstance } from './utils/http';
import { formatQuery } from '@/utils'
import { vOnLongPress } from '@vueuse/components'
import Tracker from '@/config/tracker'
import TrackerConfig from '@/config/tracker.config'

if (/yht/.test(location.hostname) && location.pathname === '/') {
  location.href = '/index.html'
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const query = formatQuery() as any

const app = createApp(App)
// 注册自定义指令
app.directive('on-long-press', vOnLongPress);

const vconsole = () => import('vconsole')
const buildEnv = (import.meta.env.PROD ? 'prod' : import.meta.env.VITE_APP_BUILD_ENV )|| 'prod'
const isPrerender = window.injectProperty && window.injectProperty.prerender
console.log('isPrerender:', isPrerender)

const SERVICE_ENV_MAPPING = {
  local: (location.host === 'res.app.ikea.cn') ? 'prod' : 'qa',
  uat: 'ppe'
}

Sentry.init({
  app,
  dsn: 'https://163a9e2bc1554baaa00d27398c519008@sentry.ingka-system.cn/83',
  environment: buildEnv || 'qa',
  integrations: [
    Sentry.browserTracingIntegration({ router }),
  ],
  trackComponents: true,
  tracesSampleRate: 1.0,
  sampleRate: 0.1,
  debug: false,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

// compatible for ikeacn
const pathnameLang = /^\/cn\//.test(location.pathname) && (location.pathname).replace(/\/cn\/([a-z]+)\/(.*)/, '$1')
if (pathnameLang) {
  query.lang = pathnameLang === 'en' ? query.lang = 'en' : query.lang = 'zh-cn'
}

const locales = {
  'en': {
    lang: 'en',
    ikeacn_lang: 'en'
  },
  'zh-cn': {
    lang: 'zh-cn',
    ikeacn_lang: 'zh',
  }
}

const requestInitOptions = getRequestInitOptions()
initAxiosInstance(requestInitOptions)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
router.beforeEach(async (to: any, from) => {
  document.title = to.meta.title || ''
  const backendTrackingParams = to?.meta?.backendTrackingParams
  console.log('backendTrackingParams:', backendTrackingParams)
  // bridge initialize
  bridge.init({
    env: SERVICE_ENV_MAPPING[buildEnv] ?? buildEnv,
    isDebug: (['qa', 'local', 'dev'].indexOf(buildEnv) > -1),
    backendTrackingParams,
    prerender: window.injectProperty && window.injectProperty.prerender
  })

  if (!(window.injectProperty && window.injectProperty.prerender)) {
    global.tracker = new Tracker({
      pageType_var: backendTrackingParams.pageType,
      pageName_var: backendTrackingParams.pageName
    })
    global.trackerConfig = TrackerConfig
    // bridge.tracking.init({ useNative: true })
  }
  
  bridge.request.init(requestInitOptions)

  const platform = (await bridge.currentPlatform()).value as any

  if (!query.validationToken && !/\/(track-and-trace)|(yht).html/.test(location.pathname) && !/yht/.test(location.hostname)) {
    const userInfo = await bridge.getUserInfo()
    if (userInfo.type !== 'success') {
      const loc = {
        protocol: location.protocol,
        hostname: location.hostname,
        port: location.port,
        pathname: location.pathname,
        search: location.search,
      }
      Sentry.captureException(new Error(JSON.stringify({ userInfo, platform, location: loc })));
    }
    if (
      // !login.value &&
      userInfo.type === 'fail' &&
      compact([
        'PcWeb',
        'MobileWeb',
        'iOS',
        'ANDROID',
        'Harmony',
        'WechatMiniprogram',
        'StoreCompanion'
        // ['prod'].indexOf(import.meta.env.VITE_APP_BUILD_ENV) > -1 ? 'unknown' : undefined,
      ]).indexOf(platform.platform) > -1
    ) {
      if (query.origin === 'SHOP_COMPANION_MINI_P') {
        const url = '/pages/login/login';
        ikea.wxJssdkLoaded.then((wx: any) => {
          wx.miniProgram.navigateTo({ url })
        })
      } else {
        bridge.navigateTo('ikea://account/login')
      }
    }
  }
})

const ikeacnLang = Cookies.get('ikeacn_lang')
// const platform = Cookies.get('platform')
console.log('query.platform:', query.platform)
const currentLang = (() => {
  if (query.platform === 'WechatMiniProgram') {
    return 'zh-cn'
  }
  if (query.lang) {
    return query.lang
  }
  if (ikeacnLang) {
    if (ikeacnLang === 'zh') {
      return 'zh-cn'
    }
    return ikeacnLang
  }
  return 'zh-cn'
})()

const locale = query.lang
  ? (query.lang && Object.keys(locales).indexOf(query.lang) > -1) ? locales[query.lang].lang : locales['zh-cn'].lang
  : locales[currentLang].lang

const i18n = createI18n({
  locale,
  messages
})

// Cookies.remove('ikeacn_lang')
// Cookies.set('ikeacn_lang',
//   (query.lang && Object.keys(locales).indexOf(query.lang) > -1)
//     ? locales[query.lang].ikeacn_lang
//     : locales['zh-cn'].ikeacn_lang,
//   { domain: buildEnv === 'local' ? '' : '.ikea.cn' }
// )

app.component('ik-layout', IkLayout)
app.component('ik-button', IkButton)
app.component('ik-price', IkPrice)
app.component('ik-card', IkCard)
app.component('ik-prod-card', IkProdCard)
app.component('ik-prod-images', IkProdImages)
app.component('ik-space-between', IkSpaceBetween)
app.component('ik-space-between-cell', IkSpaceBetweenCell)
app.component('ik-notice', IkNotice)
app.component('ik-icon', IkIcon)
app.component('ik-order-no', IkOrderNo)
app.component('ik-order-status', IkOrderStatus)
app.component('ik-service-card', IkServiceCard)
app.component('ik-items-tube', IkItemsTube)
app.component('ik-alert-message', IkAlertMessage)
app.component('ik-tag', IkTag)
app.component('ik-cell', IKCell)

app
  .use(i18n)
  .use(NavBar)
  .use(Col)
  .use(Row)
  .use(Notify)
  .use(NoticeBar)
  .use(Toast)
  .use(Sidebar)
  .use(SidebarItem)
  .use(Icon)
  .use(Empty)
  .use(Card)
  .use(CellGroup)
  .use(Cell)
  .use(Radio)
  .use(RadioGroup)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Button)
  .use(Lazyload, {
    lazyComponent: true,
    error: 'https://res.app.ikea.cn/content/u/20230324/7524908a47754cd2827982d13f34aca1.png'
  })
  .use(CountDown)
  .use(Popup)
  .use(List)
  .use(ActionSheet)
  .use(PullRefresh)
  .use(Skeleton)
  .use(Step)
  .use(Steps)
  .use(Loading)
  .use(Overlay)
  .use(Popover)
  .use(Search)
  .use(router)
  .use(store)
  
if (!window.__IKEACN__) {
  console.log('__IKEACN__:', window.__IKEACN__)
  app.mount('#app')
}

if (
  !import.meta.env.PROD ||
  query.vconsole === 'true'
) {
  if (!isPrerender) {
    vconsole().then(v => {
      // eslint-disable-next-line new-cap
      return new v.default()
      // app.use(router).mount('#app')
    })
  }
}

function getRequestInitOptions() {
  const acceptLanguageMapping = {
    'zh-cn': 'zh',
    'zh': 'zh',
    'en': 'en-us',
  }

  const requestInitOptions: IAxiosInstance = { headers: {}, withToken: true }
  if (buildEnv === 'local') {
    // requestInitOptions.headers['X-Client-Platform'] = 'WechatMiniprogram'
    requestInitOptions.headers['X-Client-Platform'] = 'iOS'
    requestInitOptions.withToken = true
  }
  if (query.validationToken) {
    requestInitOptions.withToken = false
  }
  if (query.lang === 'en') {
    requestInitOptions.headers['Accept-Language'] = 'en-us'
  } else {
    requestInitOptions.headers['Accept-Language'] = acceptLanguageMapping[Cookies.get('ikeacn_lang') || 'zh']
  }
  if (query.source) {
    requestInitOptions.headers.source = query.source
  }
  if (query.token) {
    requestInitOptions.headers.Authorization = `Bearer ${query.token}`
  }
  return requestInitOptions
}