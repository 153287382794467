import csc from './csc'
import orderActions from './orderActions'
import orderStatus from './orderStatus'
import recipient from './recipient'

export default {
  module_name: 'Order Details',
  order_no: 'Order Number',
  trade_no: 'Receipt Number',
  ikea_order_no: 'Order Number: {ikeaOrderNO}',
  apply_time: 'Order Time',
  service_order: 'Service Order',
  customer_service: 'Customer Service',
  items_count: 'total {count}',
  has_service: '',
  mobile_order: 'Moble Order',
  status: {
    ...orderStatus,
  },
  count_down_template: {
    prefix: 'Remaining Payment Time',
    suffix: '',
    format1: 'mm:ss',
    format2: 'HH:mm:ss',
    scan_go_by_csc_store: 'The order will be automatically canceled at 24:00 on the same day. Please make the payment as soon as possible.',
  },
  actions: {
    ...orderActions,
  },
  family_no: 'Family ID',
  payment_brand: 'Payment Method',
  email: 'Billing Email',
  employee_email: 'Staff Email',
  goods_amount: 'Product Amount',
  delivery_amount: 'Delivery Fee',
  service_amount: 'Service Fee',
  patService: {
    netAmount: 'Assembly service cost',
    bookedServiceTitle: 'Booked IKEA assembly service',
    expectedTitle: 'Estimated assembly service time',
    selectedTitle: 'Selected products that need to be assembled',
    installationFee: 'Installation fee',
    basicFee: 'Basic service fee ',
    moreInfo: 'More information',
    includes: '{childItemsCount} items',
    status: {
      CREATED: 'Waiting for service',
      PROCESSED: 'Waiting for service',
      CANCELED: 'Service cancelled',
      COMPLETED: 'Service completed',
    }
  },
  coupon_discounts: 'Promotional Offers',
  discounts: 'Discounts',
  family_discounts: 'Member Discount',
  employee_discounts: 'Staff Discount',
  total_discounts: 'Total Discount',
  prepaid_wallet: {
    label: 'Deducted from IKEA wallet',
    card: 'Shopping card',
    discount: 'Deducted'
  },
  total_amount: 'Total Amount',
  real_amount: 'Total Amount',
  left_to_pay: 'To Pay Amount',
  csc,
  recipient,
  assembly: {
    label: 'Units',
    header_title: 'Assembly Units',
    title: 'Assembly Units Detail',
    actions: {
      close: 'Close'
    }
  },
  service: {
    product: 'Service Product',
    price: 'Service Cost',
    actions: {
      fold: 'Fold',
      unfold: 'Unfold'
    },
    estimated_service_time: 'Estimated Service Time'
  },
}
