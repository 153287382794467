import afterSales from './after-sales'
import assembly_service from './assembly-service'
import delivery from './delivery'
import discounts_popup from './discounts-popup'
import order from './order'
import orderActions from './orderActions'
import orderStatus from './orderStatus'
import product from './product'
import store from './store'

export default {
  ...order,
  title: '订单详情',
  status: {
    ...orderStatus,
    waiting_for_payment: `${orderStatus.waiting_for_payment}`,
    canceled: `订单${orderStatus.canceled}`,
    completed: `订单${orderStatus.completed}`,
  },
  actions: {
    ...orderActions,
    fold: '收起',
    unfold: '展开',
  },
  count_down_template: {
    prefix: '',
    suffix: '后自动取消，待支付金额',
    format1: 'mm分ss秒',
    format2: 'HH时mm分ss秒',
    scan_go_by_csc_store: '将于当天24时自动取消，请尽快支付',
  },
  product,
  delivery,
  assembly_service,
  reload: '重新加载',
  service_down: '服务器开小差，请稍后再试',
  discounts_popup,
  store,
  offline: {
    ikeaOrderNo: {
      label: 'Isell订单号'
    }
  },
  afterSales: {
    ...afterSales
  },
  err: {
    forbidden_to_order: {
      title: '订单已完成支付',
      desc: '此订单无需再次支付，请和支付用户核对，如有疑问请咨询商场员工'
    },
    forbidden_scango_cancelled_order: {
      title: '订单已取消',
      desc: '此订单已取消，不支持查看，如有疑问请咨询商场工作人员'
    },
    forbidden_scango_paid_order: {
      title: '订单已完成支付',
      desc: '此订单已支付，不支持查看，如有疑问请咨询商场工作人员'
    }
  },
  feedback: {
    normal: {
      title: '请告诉我们你对本次购物的想法或建议',
      desc: '留下您的宝贵意见，帮助我们不断改进和提升服务质量',
      btnText: '告诉我们反馈',
      btnTip: '得50会员积分'
    },
    after: {
      title: '这次购物体验怎么样？',
      desc: '你的反馈将帮助我们不断进步',
      btnText: '去反馈'
    }
  },
  receivedPoints: '{receivedPoints}会员积分已到账',
  locker: {
    lockerNo: '货柜号',
    pickupNo: '取货号',
    pickupLocation: '请前往{lockerLocation}自提柜，扫码或输入取货码取货'
  },
  pickupPoint: {
    address: {
      copy: {
        success: '商场地址复制成功',
        fail: '商场地址复制失败'
      }
    },
    tips: {
      title: "不想亲自去提货？",
      content: "你也可以自行下单其他快递，取货送到家。",
      action: "了解详情"
    }
  },
  partInternalCancel: {
    title: '以下商品已取消，金额将退回至原支付账户',
    tag: '已取消'
  },
  asisProduct: {
    title: '循环集市',
    desc1: '此循环市集商品已出售，无法查看详情',
    desc2: '循环市集商品仅此一件。如果你在寻找类似的商品，欢迎浏览我们的循环市集商品页面。'
  }
}
