import { OrderChannel_Enums } from '@/enums/order'
import compare from 'compare-versions'
import { computeStatus } from '../stories/components/OrderStatus/utils'
import { getPlatformInfo } from './index'
import { version } from 'vue'

/**
 * 是否含有服务, 可以是纯服务单或者商品+服务单
 * @param { hasService }
 * @returns Boolean
 */
export const isServiceOrder = ({
  hasService
}) => {
  return !!hasService
  // return true
}

/**
 * 是否纯服务单
 * @param {hasAssemblyService, deliveryOption, deliveryInfos}
 * @returns Boolean
 */
export const isSimpleServiceOrder = ({
  // deliveryInfos,
  // hasAssembleService,
  // deliveryOption,
  isPureServiceOrder
}) => {
  return !!isPureServiceOrder
  // const _isServiceOrder = isServiceOrder({ hasService: hasAssembleService })
  // if (_isServiceOrder) {
  //   if (!deliveryOption) {
  //     if (deliveryInfos && deliveryInfos.length) {
  //       return false
  //     }
  //     return true
  //   } else {
  //     return false
  //   }
  // } else {
  //   return false
  // }
}

/**
 * 是否商品单
 * - *目前此方法只适用于详情页*
 * @param { deliveryInfos }
 * @returns
 */
export const isProdOrder = ({
  deliveryInfos
}) => {
  return !!deliveryInfos
}

/**
 * 是否CSC订单
 * @param { orderType }
 * @returns Boolean
 */
export const isCscOrder = ({
  orderType
}) => {
  return orderType && orderType.toUpperCase() === 'CSC'
  // return true
}

/**
 * 是否web
 */
export const isWeb = async () => {
  const platform = await getPlatformInfo()
  return !!platform.isWeb
  // return true
}

/**
 * 经过计算的orderStatus
 * @param { status, deliveryOption, remain }
 * @returns
 */
export const computeOrderStatus = ({
  status,
  deliveryOption,
  remain,
}) => {
  const params = {
    status,
    deliveryOption,
    remain,
  }
  return computeStatus(params)
}

/**
 * 是否含有服务信息
 * - *目前此方法只适用于详情页*
 * @param { serviceInfos }
 * @returns
 */
export const hasServiceInfos = ({
  serviceInfos
}) => {
  return !!serviceInfos
}

/**
 * 是否web
 */
export const isOfflineOrder = ({
  orderChannel
}) => {
  return orderChannel === OrderChannel_Enums.OFFLINE
}

export const ifShowOfflineOrderPostReview = async (limitation = {
  iOS: {
    version: '3.6.0',
  },
  Harmony: {
    version: '0.0.0'
  },
  ANDROID: {
    version: '3.6.0',
  },
  WechatMiniprogram: {
    version: '3.40.0',
  },
  StoreCompanion: {
    version: '3.40.0',
  },
  PcWeb: {
    version: '1.0.0',
  },
  MobileWeb: {
    version: '1.0.0',
  },
  unknown: {
    version: '1.0.0',
  }
}) => {
  const platform = await getPlatformInfo()
  return compare(platform.clientVersion, (limitation[platform.platform] || limitation.unknown).version, '>=') >= 0
}
