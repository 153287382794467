import http from '@/utils/http'

const closePrompt = async(params) => {
  return await http.post('CLICK_COLLECT_CLOSE_PROMPT', {
    ikeaOrderNo: params.ikeaOrderNo,
    orderNo: params.orderNo,
  }).then((res) => {
    console.log('closePrompt res:', res)
    return res.data && res.data || {}
  }).catch(async err => {
    console.log('closePrompt err:', err)
    return new Error(JSON.stringify(err))
  })
}

function clickCollectService() {
  return {
    closePrompt,
  }
}

export default clickCollectService()
