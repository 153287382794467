const goToPayment = function(options) {
  try {
    const { platform } = options
    let urlHost = ''
    if(platform.toUpperCase() === 'WECHATMINIPROGRAM') {
      // 门店模式
      urlHost = '/subpages/in-store/pages/payment/payment'
    } else {
      // 门店助手
      urlHost = '/pages/payment/payment'
    }
    const url = `${urlHost}?bizType=${options.bizType}&storeCode=${options.storeCode}&orderId=${options.ikeaOrderNO}&bookingCaseId=${options.checkoutId}`;
    if (window.wx) {
      window.wx.miniProgram.navigateTo({ url });
    } else {
      console.error("scan-go go to payment without window.wx:", options)
    }
  } catch (error) {
    console.error("scan-go go to payment error:", error, options)
  }
}
export default goToPayment
