import reschedule from './reschedule'

const types = {
  'parcel_delivery': '快递配送',
  'express': '极速达',
  'home_delivery': '普通配送',
  'truck_delivery': '货车配送',
  'parcel_pickup': '包裹自提',
  'standard_rd': '非指定日送达',
  'carry': '提货到家',
  'delivery': '配送到家',
  'food': '宜家餐厅'
}

const steps = {
  INIT: {
    text: '已下单',
    description: '已收到您的订单，正在准备备货。'
  },
  PREPARING: {
    text: '待发货',
    description: '正在积极为您备货，备货完成后您将收到短信通知。'
  },
  AWAITING_COLLECTION: {
    text: '待收货'
  },
  COMPLETED: {
    text: '已完成'
  }
}

const option = {
  PUOP: {
    name: '提货柜自提',
    tips: {
      a: '预计30分钟内可提货',
      b: '请留意宜家发送的短信通知',
      c: '查看提货柜位置',
    },
    scanGoTips: {
      a: '请凭宜家发送的短信',
      b: '前往商场内的提货柜取货',
      c: '查看提货柜位置',
    },
    steps: {
      INIT: steps.INIT,
      PREPARING: {
        ...steps.PREPARING,
        text: '备货中',
      },
      AWAITING_COLLECTION: {
        ...steps.AWAITING_COLLECTION,
        text: '可提货',
        description: '一切就绪！自提短信已发送至您的手机，请注意查收。请在收到短信后的当天 <span style="color: #FDB650;">22:00前</span> 完成自提，超时将进行退货处理，感谢您的理解与配合！'
      },
    },
  },
  CLICK_COLLECT_STORE: {
    name: '货物自提',
    location: '自提区',
    actions: {
      addr_nav: '地址导航',
      store_info: '商场信息',
      warehouse_info: '自提处信息',
    },
    steps: {
      INIT: steps.INIT,
      PREPARING: {
        ...steps.PREPARING,
        text: '备货中',
      },
      AWAITING_COLLECTION: {
        ...steps.AWAITING_COLLECTION,
        text: '待自提',
        description: '一切就绪！自提短信已发送至您的手机，请注意查收。请在收到短信后的 48 小时内 完成自提，超时将进行退货处理，感谢您的理解与配合！'
      },
      COMPLETED: {
        ...steps.COMPLETED,
        description: '已{actualDeliveryDateTime} {pickupPoint}完成自提，感谢您使用宜家的货物自提服务。'
      }
    },
    htmlTips: '我们的价格透明，商品价格不含运费和额外包装的费用，商场打包台提供易碎品打包材料，<strong>建议您携带打包袋来提取自提商品。</strong>',
    collectTimeLimit: {
      COLLECT_30_MINUTES: '预计30分钟内短信通知取货信息',
      COLLECT_48_HOURS: '预计48小时内短信通知取货信息'
    },
    viewPickupLocation: '查看自提点在哪儿'
  },
  HOME_DELIVERY: {
    STANDARD_RD: {
      name: '非指定日送达',
      steps: {
        INIT: {
          ...steps.INIT,
          description: ''
        },
        PREPARING: {
          ...steps.PREPARING,
          description: '我们会在您指定的三日范围内送达，确切配送时间会在您所选时间段之前至少一天通过短信给到。此配送方式下单后不可线上改期，改期请联系客服。'
        },
        AWAITING_COLLECTION: {
          ...steps.AWAITING_COLLECTION,
          text: '已发货',
          description: '我们会在您指定的三日范围内送达，确切配送时间会在您所选时间段之前至少一天通过短信给到。此配送方式下单后不可线上改期，改期请联系客服。'
        },
        COMPLETED: {
          ...steps.COMPLETED,
          description: '预计送达时间 {deliveryFromTime} - {deliveryTime}'
        }
      }
    }
  },
  SCAN_GO: {
    default: '自提点取货',
    locker: '自提柜取货',
    serviceDesk: '服务台取货'
  },
  default: {
    steps,
    store_entrance_label: '到店前欢迎探索商场'
  }
}

export default {
  title: '您的订单详情',
  tips: '商品将被分为 {batches} 批进行配送',
  gitTips: '提醒：订单中有部分商品需要调货，将于到仓后包裹统一发货，请耐心等待。',
  types,
  actual_arrive_time: '送达时间',
  arrived: '您的包裹已送达',
  expected_delivery_time: '预计配送时间',
  expected_arrive_time: '预计送达时间',
  edit: '修改',
  option,
  reschedule,
  temp_epidemics_desc: '受疫情影响，部分地区配送延迟。请查看物流了解详情。',
  parcel_delay: {
    desc: '发货时间因包裹中有部分商品需要为你特殊调货略有延迟，还请耐心等待哦',
    cs: '联系客服',
  },
}
