export const BASE_URL = {
  local: 'https://srv-qa.app.ikea.cn',
  qa: 'https://srv-qa.app.ikea.cn',
  dev: 'https://srv-qa.app.ikea.cn',
  uat: 'https://srv-ppe.app.ikea.cn',
  ctem: 'https://api-ctem-app.ingka-dt.cn',
  prod: 'https://srv.app.ikea.cn',
}

export const CMS_API_ORIGIN = {
  local: 'https://srv-qa.app.ikea.cn',
  dev: 'https://srv-qa.app.ikea.cn',
  qa: 'https://srv-qa.app.ikea.cn',
  ctem: 'https://srv-qa.app.ikea.cn',  
  uat: 'https://srv-qa.app.ikea.cn',
  ppe: 'https://srv.app.ikea.cn',
  prod: 'https://srv.app.ikea.cn',
}

export const API_LIST = {
  ORDER_LIST: '/order-service/order/list', // 订单列表
  ORDER_LIST_4_IB: '/order-service/ib/admin/order/list', // 订单列表For IB

  ORDER_DETAIL: '/order-service/order/detail',
  ORDER_DETAIL_WECHAT_WORK: '/order-service/admin/order/detail', // 企业微信查看小票的订单详情接口
  PAYLINK_ORDER_DETAIL: '/order-service/paylink/order/detail',
  DELIVERY_CONTACT_INFO: '/order-service/order/{orderNO}/deliveryContact',
  FETCH_TIME_WINDOW: '/order-service/aftersales/fetchTimeWindow',
  CHANGE_TIME_WINDOW: '/order-service/aftersales/changeTimeWindow',

  ORDER_REFUND_DETAIL: '/order-service/aftersales/refund/{orderId}',
  // https://jira.digital.ingka.com/browse/CNODI-2143
  ORDER_REFUND_DETAIL_V2: `/order-service/aftersales/refundList/{orderId}`,

  ADDON_PRODUCTS: '/shopping/addon-items/addon-products',
  // FETCH_SCENE_PRODUCTS: '/content/recommendation/v2/scene-products',
  FETCH_SCENE_PRODUCTS: '/content/feeds/order',

  CANCEL_PAID_ORDER_TTL: '/order-service/orders/cancelPaidOrderTTL',
  CANCEL_ORDER: '/order-service/order/cancel',
  ORDER_CAN_BE_CANCELLED: '/order-service/orders/canBeCancelled',
  CANCEL_GET_REASONS: '/order-service/orders/cancellation/reasonCodes/{orderStatus}',
  CANCEL_GET_REASONS_V2: '/order-service/orders/cancellation/reasonCodes/v2/{orderStatus}',
  CANCEL_PROPOSAL: '/order-service/order/cancelProposal/{orderNO}',

  FETCH_BOOKING_DETAIL: '/order-service/assemblyBookingOrder/detail',
  FETCH_BOOKING_DETAIL_V2: '/order-service/assemblyBookingOrder/detail/v2',
  ASSEMBLY_BOOKING: '/order-service/assemblyBooking',
  FETCH_TRACK_AND_TRACE_INFO: '/order-service/order/trackTrace',
  FETCH_TRACK_AND_TRACE_INFO_FOR_YHT: '/yht/trackTrace',
  FETCH_TRACK_AND_TRACE_INFO_FOR_YHT_CSC: '/yht/trackTraceSkipValid',
  
  CHECK_REVIEW: '/order-service/review/checkReview',

  FETCH_ALL_STORES: '/store/all',

  POST_VIEW_LIST: '/order-service/order/common/review/list', // 待晒单页面的list

  FIRST_REGISTER: '/geetest/firstRegister',
  ADD_TO_CART: '/shopping/cart/v3/add-to-cart',

  APP_DOWNLOAD_GUIDE: '/marketing/app-download-guide',

  CLICK_COLLECT_CLOSE_PROMPT: '/order-service/order/extendedClickCollect/prompt/close',
}

export default {
  BASE_URL,
  API_LIST
}
