import { storesService } from '@/service'
import qs from 'qs'
import Cookies from 'js-cookie'
import { formatQuery } from '@/utils'
const query = formatQuery()

const queryLang = query.lang
const cookieLang = Cookies.get('ikeacn_lang')

const langMapping = {
  'zh': 'zh',
  'zh-cn': 'zh',
  'en': 'en-us',
}

const getDefaultState = () => {
  return {
    data: [],
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    updateStores(state, newState) {
      state = newState
    },
    resetStores(state) {
      Object.assign(state, getDefaultState())
    }
  },
  actions: {
    fetchStoreList({ state }) {
      state.data = []
      state.requestError = false
      state.status = 'PENDING'
      storesService.getAllStores({
        lang: langMapping[queryLang || cookieLang || 'zh']
      }).then(res => {
        if (res instanceof Error) {
          state.requestError = true
          state.state = 'REJECTED'
        } else {
          state.data = res
          state.status = 'FULFILLED'
        }
      }).catch(err => {
        state.requestError = true
        state.state = 'REJECTED'
        console.log(err)
      })
    }
  }
}

