import orderStatus from './orderStatus'
export default {
  back: '返回',
  cancel: '取消',
  iknow: '我知道了',
  close: '关闭',
  finished: '完成',
  date: {
    mmdd: 'MM月DD日',
    yyyymmdd: 'YYYY年MM月DD日',
    sameDay: '今天',
    nextDay: '明天',
    afterTomorrow: '后天'
  },
  mobileNoticeTip: '开启通知，随时了解发货、售后等动态。',
  mobileNoticeTipSwitch: '去开启',
  orderStatus
}
