import bridge from '@/bridge';
import { Origin_Enums } from '@/enums';
import md5 from 'md5';
export default class Tracker {
  private platform_var: string;
  readonly pageType_var: string;
  readonly pageName_var: string;
  private mode_var: string; // 是否门店模式
  private ut_var: string | number[];

  constructor(payload) {
    bridge.tracking.init({ useNative: true })
    this.platform_var = ''
    this.ut_var = ''
    this.mode_var = ''
    this.pageType_var = payload.pageType_var
    this.pageName_var = payload.pageName_var
    this.setPlatformVar()
    this.setToken()
    this.setModeVar()
  }
  private setToken = async () => {
    const token = await bridge.getToken()
    this.ut_var = md5(token.value)
  }
  private setPlatformVar = async () => {
    const result = await bridge.currentPlatform()
    const platform = result.value?.platform.toString().toUpperCase() ?? ''
    if (platform === 'IOS') {
      this.platform_var = 'iOS'
    } else if (platform === 'ANDROID') {
      this.platform_var = 'Android'
    } else if (platform === 'HARMONY') {
      this.platform_var = 'Harmony'
    } else if (platform === 'WECHATMINIPROGRAM') {
      this.platform_var = 'WechatMiniprogram'
    } else if (platform === 'STORECOMPANION') {
      this.platform_var = 'SC'
    } else if (platform === 'PCWEB') {
      this.platform_var = 'pc'
    } else {
      this.platform_var = 'h5'
    }
  }
  private setModeVar = async () => {
    const result = await bridge.currentPlatform()
    const ifMiniProgram = result.value?.platform.toString().toUpperCase() === 'WECHATMINIPROGRAM'
    const ifSC = new URLSearchParams(window.location.search).get('origin') === Origin_Enums.SCAN_GO
    this.mode_var = (ifMiniProgram && ifSC) ? 'store' : ''
  }

  public sendTrack = async (name, params, eventKey = '') => {
    if (params?.message_var) {
      window.parent.postMessage({
        name: 'MESSAGE_FROM_ORDER',
        type: 'track',
        value: params.message_var
      }, '*')
    }
    try {
      console.log('this.platform_var', this.platform_var)
      bridge.tracking.track(name, eventKey, {
        eventName: name,
        platform_var: this.platform_var,
        pageType_var: this.pageType_var,
        pageName_var: this.pageName_var,
        mode_var: this.mode_var,
        ut_var: this.ut_var,
        timestamp_var: new Date().valueOf(),
        ...params,
      })
    } catch (err) {
      console.log('trackerErr')
    }
  }
}
