import { CMS_API_ORIGIN } from '@/config/http.config';
import { CMS_IDS, Origin_Enums } from '@/enums';
import http from '@/utils/http';
import niceTry from 'nice-try';
import qs from 'qs';
export interface IPlatform {
  clientVersion: string,
  osVersion: string,
}

export interface IProcessEnv {
  GAODE_DMP_MAP_URL: string,
}

export interface IPageInfo {
  type: 'order-list' | 'order-detail' | 'order_refund' | 'review_order_list' | 'order_assembly_service' | 'order_aftersales_detail',
  name: '订单列表页' | '订单详情页' | '钱款去向页' | '待晒单订单页' | '组装服务页' | '售后详情页'
}

export interface IIkeaReturn {
  type: "success" | "fail";
  value?: string | undefined;
  msg: string;
}

export interface KV {
  [key: string]: any
}

export interface ICurrent {
  page: 'OrderList' | 'OrderDetail',
}

export interface IGlobalState {
  processEnv: IProcessEnv | null,
  pageInfo: IPageInfo | null,
  platform: IPlatform | null,
  current: ICurrent | null,
  qiankun?: HTMLElement | null,
  externalHeader?: Element | null,
  win?: KV,
  lang?: IIkeaReturn,
  origin?: Origin_Enums | string,
  extras?: KV,
  isFromScanGo?: boolean, // 是否来自scan&go小程序
  assertConfig: KV,
  logisticsConfig: KV,
  notifyEmptyDeliveryInfo: KV,
  additionalFlag?: string | undefined, // 附加标记
  isNoticeOpen?: boolean,
  refundModalShow?: boolean,
  orderDetailActionBtns?: any,
  CFPickupConfig: KV // CF订单（scango）自提文案配置
}

const getDefaultState = (): IGlobalState => {
  return {
    processEnv: null,
    pageInfo: null,
    platform: null,
    current: null,
    assertConfig: {},
    logisticsConfig: {},
    notifyEmptyDeliveryInfo: {},
    isNoticeOpen: true,
    refundModalShow: false,
    CFPickupConfig: {}
  }
}
  
export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    updateProcessEnv(state: IGlobalState, payload: IProcessEnv) {
      state.processEnv = payload
    },
    updatePageInfo(state: IGlobalState, payload: IPageInfo) {
      state.pageInfo = payload
    },
    updatePlatform(state: IGlobalState, payload: IPlatform) {
      state.platform = payload
    },
    updateCurrent(state: IGlobalState, payload: ICurrent) {
      state.current = payload
    },
    updateQianKun(state: IGlobalState, payload: HTMLElement | null) {
      state.qiankun = payload
    },
    updateExternalHeader(state: IGlobalState, payload: Element | null) {
      state.externalHeader = payload
    },
    updateWin(state: IGlobalState, payload: KV) {
      state.win = payload
    },
    updateLang(state: IGlobalState, payload: IIkeaReturn) {
      state.lang = payload
    },
    updateOrigin(state: IGlobalState, payload: string) {
      state.origin = payload
    },
    updateExtras(state: IGlobalState, payload: string) {
      const extras = niceTry(() => qs.parse(payload)) || {}
      extras.isAutoJump = extras.autoJump === 'true'
      extras.isUnbindOrder = extras.unbind_order === 'true'
      extras.isIBAdmin = extras.isIBAdmin === 'true'
      state.extras = extras
    },
    updateIsFromScanGo(state: IGlobalState, payload: boolean) {
      state.isFromScanGo = payload
    },
    updateAssertConfig(state: IGlobalState, payload: KV) {
      state.assertConfig = payload
    },
    updateLogisticsConfig(state: IGlobalState, payload: KV) {
      state.logisticsConfig = payload
    },
    updateNotifyEmptyDeliveryInfo(state: IGlobalState, payload: KV) {
      state.notifyEmptyDeliveryInfo = payload
    },
    updateAdditionalFlag(state: IGlobalState, flag: string | undefined) {
      state.additionalFlag = flag
    },
    updateIsNoticeOpen(state: IGlobalState, payload: boolean) {
      state.isNoticeOpen = payload
    },
    updateRefundModalShow(state: IGlobalState, payload: boolean) {
      state.refundModalShow = payload
    },
    updateOrderDetailActionBtns(state: IGlobalState, payload: any) {
      state.orderDetailActionBtns = payload
    },
    updateCFPickupConfig(state: IGlobalState, payload: KV) {
      state.CFPickupConfig = payload
    }
  },
  actions: {
    async getCMSAssertConfig({ commit }) {
      try {
        const cms_api_origin = CMS_API_ORIGIN[import.meta.env.VITE_APP_BUILD_ENV || 'prod']
        const assertConfig = await http.get(`${cms_api_origin}/cms-service/client-api/data-resources/data-resource/${CMS_IDS.ASSERT_CONFIG}`)
          .then((res) => {
            return res.data
          })
          .catch(err => {
            console.error(err)
            return null
          })
        if (assertConfig?.content) {
          commit('updateAssertConfig', assertConfig.content)
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getCMSLogisticsConfig({ commit }) {
      try {
        const cms_api_origin = CMS_API_ORIGIN[import.meta.env.VITE_APP_BUILD_ENV || 'prod']
        const logisticsConfig = await http.get(`${cms_api_origin}/cms-service/client-api/data-resources/data-resource/${import.meta.env.VITE_APP_CMS_KEY_4_LOGISTICS_CONFIG}`)
          .then((res) => {
            return res.data
          })
          .catch(err => {
            console.error(err)
            return null
          })
        if (logisticsConfig?.content) {
          commit('updateLogisticsConfig', logisticsConfig.content)
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getCMSNotifyEmptyDeliveryInfo({ commit }) {
      try {
        const cms_api_origin = CMS_API_ORIGIN[import.meta.env.VITE_APP_BUILD_ENV || 'prod']
        const notifyEmptyDeliveryInfo = await http.get(`${cms_api_origin}/cms-service/client-api/data-resources/data-resource/${import.meta.env.VITE_APP_CMS_KEY_4_NOTICE_EMPTY_DELIVERY_INFO}`)
          .then((res) => {
            return res.data
          })
          .catch(err => {
            console.error(err)
            return null
          })
        if (notifyEmptyDeliveryInfo?.content) {
          commit('updateNotifyEmptyDeliveryInfo', notifyEmptyDeliveryInfo.content)
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getCFPickupConfig({ commit }) {
      try {
        const cms_api_origin = CMS_API_ORIGIN[import.meta.env.VITE_APP_BUILD_ENV || 'prod']
        await http.get(`${cms_api_origin}/cms-service/client-api/data-resources/data-resource/${import.meta.env.VITE_APP_CMS_KEY_4_CF_PICKUP_TEXT_CONFIG}`)
          .then((res) => {
            if (res?.data?.content) {
              commit('updateCFPickupConfig', res.data.content)
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
  }
}
